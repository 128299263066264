import React, { Component } from "react";
import axios from "axios";
const api = axios.create({
  baseURL: "http://localhost:8080/api/v1",
});

class FormsLink extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        defDossier: 4913,
      },
      mounted: false,
      datadefDossier: [],
      isChecked: true,
      Links: 65088,
    };
    this.fetchDossierFields = this.fetchDossierFields.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const newFormData = {
      defDossier: this.state.form.defDossier,
    };
    let files = [];
    const dossierLinked = {};
    let link = "";
    for (let entry of formData) {
      const [key, value] = entry;
      const ids = key.split("_");
      if (ids.length > 1) {
        dossierLinked[ids[1]] = value;
        dossierLinked.defDossier = "3654";
        link = ids[0];
      } else if (value instanceof File) {
        const fileId = await this.getFilesIds(value);
        files = [...files, parseInt(fileId[0].id)];
        newFormData[key] = files;
      } else {
        newFormData[key] = /^\d+$/.test(value) ? parseInt(value) : value;
      }
    }
    const dossierId = await api.post("/forms/createdDossier", dossierLinked);
    newFormData[link] = [dossierId.data.id];
    await api.post("/dossiers", newFormData);
  };

  fetchDossierFields = async (defDossier) => {
    const { data } = await axios.get(
      `http://localhost:8080/api/v1/forms/displayitem?defDossier=${defDossier}`
    );

    this.setState({ datadefDossier: data.results });
    data.results.forEach(async (field) => {
      if (field.def_dossier_link) {
        const select = document.querySelector(`[name="${field.id}"]`);
        if (select) {
          const { data } = await axios.get(
            `http://localhost:8080/api/v1/forms/defdossier?defDossier=${field.def_dossier_link.child_def_dossier_id}`
          );
          for (let i = 0; i < data.results.contents.length; i++) {
            if (data.results.dossiers[i] && data.results.dossiers[i].summary) {
              const element = data.results.dossiers[i].summary;
              const opt = document.createElement("option");
              opt.value = element.id;
              opt.innerText = element;
              select.add(opt);
            }
          }
        }
      }
    });
  };

  componentDidMount() {
    if (!this.state.mounted) {
      //this.fetchDossierFields(4913);
      this.setState({ mounted: true });
    }
  }
  render() {
    return (
      <div className="container">
        <h3>Doble list</h3>
        <div className="row bg-light p-5">
          <form onSubmit={this.handleSubmit}>
            <div className="mb-3 row">
              <p>DefDossier 4913</p>
              <label className="form-label">Nombre</label>
              <div className="">
                <input type="text" name="64050" className="form-control" />
              </div>
              <label className="form-label">Email</label>
              <div className="">
                <input type="text" name="65105" className="form-control" />
              </div>
              <label className="form-label">Curso</label>
              <div className="">
                <input type="text" name="65108" className="form-control" />
              </div>
            </div>

            <div className="mb-3 row">
              <label className="form-label">Nombre del estudiante</label>
              <div className="">
                <input
                  type="text"
                  name="65110_54292"
                  className="form-control"
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label className="form-label">Apellido del estudiante</label>
              <div className="">
                <input
                  type="text"
                  name="65110_54289"
                  className="form-control"
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FormsLink;
