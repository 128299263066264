import "./App.css";
import FormsContainer from "./containers/FormsContainer";
import FormsLink from "./containers/FormsLink";

function App() {
  return (
    <div className="App">
      <header className="">
        <FormsContainer></FormsContainer>
        <FormsLink></FormsLink>
      </header>
    </div>
  );
}

export default App;
