import React, { Component } from "react";
import axios from "axios";

const api = axios.create({
  baseURL: "http://localhost:8080/api/v1",
});

class FormsContainer extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        defDossier: 3652,
      },
      datadefDossier: [],
      isChecked: true,
      Links: 65088,
    };
    this.fetchDossierFields = this.fetchDossierFields.bind(this);
    this.getFilesIds = this.getFilesIds.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const newFormData = {
      defDossier: this.state.form.defDossier,
    };
    let files = [];
    for (let entry of formData) {
      const [key, value] = entry;
      const ids = key.split("_");
      if (ids.length > 1) {
        //console.log(value, key);
      } else if (value instanceof File) {
        const fileId = await this.getFilesIds(value);
        files = [...files, parseInt(fileId[0].id)];
        newFormData[key] = files;
      } else {
        newFormData[key] = /^\d+$/.test(value) ? parseInt(value) : value;
      }
    }
    await api.post("/dossiers", newFormData);
  };

  handleFileChange = (e) => {
    const form = this.state.form;
    form[e.target.name] = {
      type: "files",
      files: e.target.files,
    };
    this.setState({ form });
  };

  getFilesIds = async (files) => {
    const formData = new FormData();
    formData.append("files", files);

    const { data } = await api.post("/files", formData);

    return data;
  };

  fetchDossierFields = async (defDossier) => {
    const { data } = await api.get(`/displayitem?defDossier=${defDossier}`);

    data.results.forEach(async (field) => {
      if (field.def_dossier_link) {
        const select = document.querySelector(`[name="${field.id}"]`);

        if (select) {
          const { data } = await api.get(
            `/dossiers?defDossier=${field.def_dossier_link.child_def_dossier_id}`
          );
          for (let i = 0; i < data.results.dossiers.length; i++) {
            const element = data.results.dossiers[i];
            const opt = document.createElement("option");
            opt.value = element.id;
            opt.innerText = element.summary;
            select.add(opt);
          }
        }
      }
    });
  };

  componentDidMount() {
    this.fetchDossierFields(3652);
  }
  render() {
    return (
      <div className="container">
        <h1 className="row mb-5">Formulario de pruebas</h1>
        <div className="d-flex  gap-2 align-items-center">
          <p>defdossier:  {this.state.form.defDossier}</p>
         
        </div>
        <div className="row bg-light">
          <form onSubmit={this.handleSubmit}>
            <p>Campo Name del dossier requerido id (54273)</p>
            <div className="mb-3 d-flex align-items-center gap-2">
              <label for="name" className="form-label">
                Nombre del curso
              </label>
              <input type="text" name="54273" id="name" class="form-control" />
            </div>
            <p>Campo linkeado de (teachers) id (65119)</p>
            <div className="mb-3 d-flex align-items-center gap-2">
              <label for="name" className="form-label">
                Profesor
              </label>
              <select name="65119" className="form-select">
                <option selected>Selecciona una opción</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="formFileMultiple" class="form-label">
                Cargar contenido programatico
              </label>
              <input
                class="form-control"
                type="file"
                id="formFileMultiple"
                name="65092"
                multiple
                accept=".doc,.docx,.pdf"
                onChange={this.handleFileChange}
              />
            </div>
            <button type="submit" class="btn btn-primary">
              Send
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default FormsContainer;
